<template>
  <div>
    <h3>M5</h3>
    <p>
      A lightweight-ish local development environment aimed to reduce complexity
      of bootstraping a Kubernetes Environment
    </p>
    <b-container class="bv-example-row">
      <b-row class="">
        <b-col></b-col>
        <b-col cols="8">
          <p class="text-left">Copy M5 script to your project</p>

          <code>curl -sfL https://get.projectm5.mobomo.net | sh</code>

          <p class="text-left">Install M5 into your project</p>

          <code>./m5.sh --install</code>

          <p class="text-left">Optional variables for installation</p>

          <b-list-group>
            <b-list-group-item>M5_DEBUG=true</b-list-group-item>
            <b-list-group-item>M5_COPYLOCAL=true</b-list-group-item>
            <b-list-group-item
              >M5_INSTALL_PROJECTNAME=some_name</b-list-group-item
            >
          </b-list-group>

          <br />
          <p class="text-left">Example on how to use variables</p>

          <code>
            M5_DEBUG=true M5_COPYLOCAL=true M5_INSTALL_PROJECTNAME=some_name
            ./m5.sh --install
          </code>
        </b-col>
        <b-col></b-col>
      </b-row>
      <b-row class="">
        <b-col></b-col>
        <b-col cols="8">
          <h3>Common Problems</h3>
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-1 variant="info"
                  >ERROR: Head
                  "https://registry-1.docker.io/v2/rancher/k3s/manifests/latest":
                  unauthorized: incorrect username or password</b-button
                >
              </b-card-header>
              <b-collapse
                id="accordion-1"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  You likey have some incorrect configuration for accessing
                  docker hubs registry within your ~/.docker/config file. Or you
                  need to login with docker login
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="info"
                  >Bind for 0.0.0.0:6443 failed: port is already
                  allocated</b-button
                >
              </b-card-header>
              <b-collapse
                id="accordion-2"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    You have another container or process on your machine
                    consuming the neccessary ports for M5 to work. Run the
                    following command to check for other containers using those
                    ports
                    <code>docker ps</code>

                    It is also possible you have another M5 Project running
                    somewhere. At this moment, M5 uses specific ports so you can
                    only run one single instance of the environment at a time.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "CodeExample",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.text-left {
  text-align: left !important;
}
code,
.code,
pre {
  -webkit-text-size-adjust: 100%;
  font-weight: 300;
  box-sizing: inherit;
  display: block;
  border-left: 4px solid #842000;
  font-size: 15px;
  color: #000;
  text-shadow: 0 1px #fff;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
  padding: 1em;
  overflow: auto;
  background: #f5f2f0;
  margin: 15px 0;
}
.list-group {
  text-align: left !important;
}
</style>
